
<!--Pagina de Iniciar Sesión-->
<template>
  <v-container
    fill-height
    fluid
    class="black d-flex align-stretch justify-center view_h"
  >
    <v-row class="" justify="center" no-gutters>
      <v-col cols="12" md="11" class="py-0 px-0">
        <v-toolbar elevation="0" class="menugrande black">
          <img
            :src="require('../assets/beckslogo3.png')"
            width="150px"
            alt="Logo Cerveza Beck´s"
            title="Logo Cerveza Beck´s"
            class="ml-15"
          />
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row justify="center" class="my-0 px-0 py-n10">
          <v-col cols="12" md="6" class="px-0 py-0"
            ><!-- Contenido de la izquierda-->
            <v-img
              :aspect-ratio="1"
              width="100%"
              min-width="200"
              class="d-none d-md-flex align-center justify-start"
              :src="require('../assets/becksjustificacionweb.jpg')"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="5" class="text-center">
            <!--Contenido de la derecha-->
            <div class="text-center">
              <h1 class="white--text textoR">PARTICIPA</h1>
              <h2 class="white--text my-10" style="font-size: 0.83em;">
                REG&Iacute;STRATE O INICIA SESI&Oacute;N
              </h2>
              <v-spacer />
              <!--Card con la vista de iniciar session-->
              <v-card color="black" max-width="400" class="mx-auto text-left" v-show="!resetPass">
                <v-text-field
                  v-model="email"
                  height="45px"
                  :rules="emailRules"
                  placeholder="CORREO ELECTRÓNICO"
                  required
                  class="my-n1 rounded-pill"
                  outlined
                  rounded
                  dark
                >
                  <template v-slot:label>
                    <div class="grey--text " style="font-size: 10pt">
                      CORREO ELECTRÓNICO
                    </div>
                  </template>
                </v-text-field>

                <v-text-field
                  type="password"
                  v-model="password"
                  :rules="[(v) => !!v || 'INGRESA TU CONTRASEÑA']"
                  height="45px"
                  label="CONTRASEÑA"
                  pattern="[a-zA-Z]"
                  required
                  outlined
                  class="mt-n1 rounded-pill mb-n5"
                  rounded
                  dark
                >
                  <template v-slot:label>
                    <div class="grey--text " style="font-size: 10pt">
                      CONTRASEÑA
                    </div>
                  </template>
                </v-text-field>
                <span 
                  class="white--text mt-3 text-right" 
                  style="font-size: 8pt; text-decoration: underline; width: 100%; display: block; cursor: pointer; z-index: 10;" 
                  @click="resetPass = !resetPass"
                > 
                  Olvide mi contraseña
                </span>

                <v-btn 
                  large
                  rounded min-width="300px" 
                  block
                  color="becks" 
                  class="mx-auto mt-10"  
                  @click="login"
                  onclick="eventoBecks('Continuar-Manifiesto','Clic')"
                >
                  Iniciar Sesión
                </v-btn> 

                <br>
                <hr class=""/>
                <br>

                <v-btn 
                  large
                  rounded min-width="300px" 
                  block
                  color="becks" 
                  class="mx-auto"  
                  @click="cambiarP('IniciarSesions')" 
                  
                >
                  Regístrate
                </v-btn>
                <!--Comentario de los botones antiguos 
                <v-btn
                  block
                  color="white"
                  class="my-3"
                  rounded
                  large
                  @click="doClickFacebook()"
                >
                  <v-icon x-large>mdi-facebook</v-icon>FACEBOOK
                  <v-spacer />
                </v-btn>

                <v-btn
                  large
                  block
                  color="white"
                  rounded
                  id="twitter"
                  @click="loginTwitter()"
                  class="profile my-3"
                >
                  <v-icon x-large>mdi-twitter</v-icon>TWITTER
                  <v-spacer />
                </v-btn>

                <v-btn
                  block
                  color="white"
                  class="my-3"
                  large
                  rounded
                  @click="doClickGoogle()"
                >
                  <v-icon x-large>mdi-google</v-icon>GOOGLE
                  <v-spacer />
                </v-btn>
                <GoogleLogin
                  class="d-none"
                  id="google"
                  :params="params"
                  :renderParams="renderParams"
                  :onSuccess="onSuccess"
                  :onFailure="onFailure"
                >
                </GoogleLogin>

                <facebook-login
                  class="transparent"
                  id="facebook"
                  appId="1299057643880323"
                  @login="onLogin"
                  @logout="onLogout"
                  @get-initial-status="getUserData"
                  @sdk-loaded="sdkLoaded"
                >
                </facebook-login>
                -->
              </v-card>
              <!--Card con la vista de recuperar contraseña-->
              <v-card color="black" max-width="400" class="mx-auto text-left" v-show="resetPass">
                <h2 class="white--text my-10" style="font-size: 0.83em;">
                  Recupera tu contraseña
                </h2>
                <v-text-field
                  v-model="email"
                  height="45px"
                  :rules="emailRules"
                  placeholder="CORREO ELECTRÓNICO"
                  required
                  class="my-n1 rounded-pill"
                  outlined
                  rounded
                  dark
                >
                  <template v-slot:label>
                    <div class="grey--text " style="font-size: 10pt">
                      CORREO ELECTRÓNICO
                    </div>
                  </template>
                </v-text-field>

                <v-btn 
                  large
                  rounded min-width="300px" 
                  block
                  color="becks" 
                  class="mx-auto mt-10"  
                  @click="recuperarPwd()"
                >
                  Recuperar contraseña
                </v-btn> 

                <span 
                  class="white--text mt-3 text-center" 
                  style="font-size: 10pt; text-decoration: underline; width: 100%; display: block; cursor: pointer; z-index: 10;" 
                  @click="resetPass = !resetPass"
                > 
                  o inicia Sesion
                </span>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      dark
      color="becks"
      class=" d-flex justify-center"
      v-model="snackbar"
      :timeout="4000"
      >
        <span class=" d-flex justify-center text-center black--text">{{snackbarText}}</span>
      </v-snackbar>
  </v-container>
</template>
<script>
import menus from "../components/menus.vue";
import axios from "axios";
import facebookLogin from "facebook-login-vuejs";
import GoogleLogin from "vue-google-login";

import { initializeApp } from "firebase/app";
// import { initializeApp }from 'firebase/auth';
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  TwitterAuthProvider,
} from "firebase/auth";

export default {
  data: () => ({
    snackbarText: "",
    snackbar: false,
    resetPass: false,
    checkbox: false,
    isConnected: false,
    emailRules: [
      (v) => !!v || "E-mail es requerido",
      (v) => /.+@.+\..+/.test(v) || "El E-mail no es válido",
    ],
    password: "",
    email: "",
    personalID: "",
    pass: "",
    FB: undefined,
    params: {
      client_id:
        "138736272385-659vnsfrq835qpgd5sh50ouoi1qc4juu.apps.googleusercontent.com",
    },
    renderParams: {
      width: 250,
      height: 50,
      longtitle: true,
    },
  }),
  methods: {
    recuperarPwd(){
      var vm2 = this
      axios
          .post("/resetpwd.php", {
              email: this.email
          })
          .then(function (response) {
            if (response.data.message == "vacio") {
              vm2.resetPass = false
              vm2.snackbarText= "Tu correo no fue encontrado, ¡Registrate!"
              vm2.snackbar = true
            }else{
              vm2.resetPass = false
              vm2.snackbarText= "Tu nueva contraseña a sido enviada a tu correo electrónico!"
              vm2.snackbar = true
            }
          })
          .catch((error) => {
            
          });
    },
    cambiarP(lugar) {
      axios
        .get("/")
        .then((response) => this.$router.push({ name: lugar }))
        .catch((error) => console.log("Hubo un error: " + error));
    },
    async login(){
      var vm = this
        axios
          .post("/login.php", {
              email: this.email,
              pwd: this.password,
              check: this.checkbox,
          })
          .then(function (response) {

            if (response.data.message == "vacio") {

                vm.$router.push({name: 'IniciarSesions'})

            }else {

              if(response.data.message == "pwd valido"){
                  vm.$router.push({name: 'Manifiesto'})
              }else{
                  alert('Password invalido'); //Esto hay que cambiarlo por una alerta de vue o en el input
              }
            }

          })
          .catch((error) => {
            
          });
    },
    // Funciones para el login de Facebook
    getUserData() {
      var vm = this
      FB.init({
            appId: '1299057643880323',
            cookie: true,
            status: true,
            xfbml: true,
            version: 'v3.2'
            });

      // if (typeof api !== 'undefined') {
      this.FB.api(
        "/me",
        "POST",
        { fields: "id,name,email" },
        function (response) {
          // datos obtenidos de FACEBOOK
          var vm2 = vm
          var user_id = response.id;
          var user_name = response.name;
          var user_email = response.email;

          // Envio del correo que deberia ser UNICO
          // a la instalacion de drupal para buscar si ya esta regitrado
          axios
            .post("/query.php", {
              validate_name: user_name,
              validate_email: user_email,
              validate_id: user_id,
            })
            .then(function (response) {
              // console.log(response)
              // console.log(response.data.message)

              if (response.data.message == "validado") {
                // console.log("el usuario segun existe")
                vm2.$router.push({name:'Manifiesto'})
               // window.location.replace("/Manifiesto");
              } else {
                // console.log("el usuario segun NO existe")
                //enviando datos a drupal
                var json = {
                  name: { value: user_name},
                  mail: { value: user_email },
                  pass: { value: user_id },
                };

                let header = {
                  headers: { "Content-Type": "application/json" },
                };

                const res = axios
                  .post("/api/user/register?_format=json", json, header)
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    // console.log(error)
                    // console.log("El usuario ya existe")
                  });

                sessionStorage.email = user_email;
                sessionStorage.name = user_name;
                sessionStorage.provider_id = user_id;

                vm2.$router.push({name:'IniciarSesions'})
                //window.location.replace("/IniciarSesions");
              }
            })
            .catch((error) => {
              // console.log(error)
            });
        }
      );
      
    },
    sdkLoaded(payload) {
      this.isConnected = payload.isConnected;
      this.FB = payload.FB;
      if (this.isConnected) this.getUserData();
    },
    onLogin() {
      this.isConnected = true;
      this.getUserData();
    },
    onLogout() {
      this.isConnected = false;
    },
    // Funciones para el login de Google
    onSuccess(googleUser) {
      // var user_info = JSON.stringify(googleUser.getBasicProfile());
      // var userinfo = JSON.parse(user_info);

      var profile = googleUser.getBasicProfile();

      // console.log(userinfo)
      // Datos obtenidos de Google
      var user_id = profile.getId();
      var user_name = profile.getName();
      var user_email = profile.getEmail();

      // console.log(user_id)
      // console.log(user_name)
      // console.log(user_email)

      var vm = this
      axios
        .post("/query.php", {
          validate_name: user_name,
          validate_email: user_email,
          validate_id: user_id,
        })
        .then(function (response) {
          // console.log(response)
          // console.log(response.data.message)

          if (response.data.message == "validado") {
            // console.log("el usuario segun existe")

            vm.$router.push({name:'Manifiesto'})
            //window.location.replace("/Manifiesto");
          } else {
            // console.log("el usuario segun NO existe")

            //enviando datos a drupal
            var json = {
              name: { value: user_name },
              mail: { value: user_email },
              pass: { value: user_id },
            };

            let header = { headers: { "Content-Type": "application/json" } };

            const res = axios
              .post("/api/user/register?_format=json", json, header)
              .then((response) => {
                // console.log(response)
              })
              .catch((error) => {
                // console.log(error)
                // console.log("El usuario ya existe")
              });

            sessionStorage.email = user_email;
            sessionStorage.name = user_name;
            sessionStorage.provider_id = user_id;

            vm.$router.push({name: 'IniciarSesions'})
            //window.location.replace("/IniciarSesions");
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    },
    // Funciones para el login de Twitter
    loginTwitter() {
      const firebaseConfig = {
        apiKey: "AIzaSyAJe76qMJBabZfd9OPpCHWdMsmu72DXa5s",
        authDomain: "becks-330319.firebaseapp.com",
        projectId: "becks-330319",
        storageBucket: "becks-330319.appspot.com",
        messagingSenderId: "138736272385",
        appId: "1:138736272385:web:316ccb4a8b2473a2eaec00",
      };

      const app = initializeApp(firebaseConfig);
      const provider = new TwitterAuthProvider();
      const auth = getAuth();

      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = TwitterAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const secret = credential.secret;

          // The signed-in user info.
          const user = result.user;
          // console.log(result.user)

          const user_auth = auth.currentUser;
          // console.log(user_auth)

          var user_id = user_auth.uid;
          var user_name = user_auth.displayName;
          var user_email = user_auth.email;

          // console.log("ID: "+user_id)
          // console.log("Nombre: "+user_name)
          // console.log("Email"+user_email)
          var vm = this
          axios
            .post("/query.php", {
              validate_name: user_name,
              validate_email: user_email,
              validate_id: user_id,
            })
            .then(function (response) {
              if (response.data.message == "validado") {
                // console.log("el usuario segun existe")

                vm.$router.push({name: 'Manifiesto'})
                //window.location.replace("/Manifiesto");
              } else {
                // console.log("el usuario segun NO existe")
                //enviando datos a drupal
                var json = {
                  name: { value: user_name },
                  mail: { value: user_email },
                  pass: { value: user_id },
                };

                let header = {
                  headers: { "Content-Type": "application/json" },
                };
                const res = axios
                  .post("/api/user/register?_format=json", json, header)
                  .then((response) => {
                    // console.log(response);
                  })
                  .catch((error) => {
                    // console.log(error);
                    // console.log("El usuario ya existe")
                  });

                // sessionStorage.email = user_email;
                // sessionStorage.name = user_name;
                // sessionStorage.provider_id = user_id;
                vm.$router.push({name: 'IniciarSesions'})
                //window.location.replace("/IniciarSesions");
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = TwitterAuthProvider.credentialFromError(error);
        });
    },
    doClickFacebook() {
      document.getElementById("facebook").firstElementChild.click();
    },
    doClickGoogle() {
      document.getElementById("google").click();
    }
  },
  computed: {},
  created(){
    // this.deleteCookie();
    this.$vuetify.goTo(0);
  },
  name: "IniciarSesion",
  components: {
    menus,
    facebookLogin,
    GoogleLogin,
  },
};
</script>
<style scoped>
.textoR {
  font-size: 30pt;
  font-weight: bold;
}
.transparent {
  opacity: 100%;
  max-width: 10px;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  filter: alpha(opacity=100);
}

@media (max-width: 960px) {
  .textoR {
    font-size: 20pt;
    font-weight: bold;
  }
  .view_h {
    min-height: 900px;
  }
}
.v-label{
  font-size: 10pt;
}
</style>